import axios from 'axios'

import {ElLoading, ElMessage, ElMessageBox} from 'element-plus'

import Global from '../Global.js'

import {businessGet} from '../api/business.js'

import html2canvas from 'html2canvas'

import {ElNotification} from 'element-plus'

import router from '../router/index.js'

export function utilsUploadFile(file, module, objName) {
    return new Promise(resolve => {
        var newFormData = new FormData();
        newFormData.append("file", file);
        newFormData.append("module", module);
        let loadingInstance = ElLoading.service({
            lock: true,
            text: '正在上传文件',
        });
        axios({
            method: "post",
            url: process.env.VUE_APP_API_URL + '/admin/v1/upload',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: false,
            data: newFormData
        }).then(res => {
            loadingInstance.close();
            if (res.data.status == 200) {
                let msg = res.data.data;
                if (objName) {
                    msg.objName = objName
                }
                resolve(msg)
            }
            else {
                ElMessage({
                    showClose: true,
                    message: file.name + '，该文件上传失败',
                    type: 'warning'
                });
                resolve(null)
            }
        });
    })
}

export function utilsGetOptionsList(val) {
    let optionsObj = Global[val];
    let newArray = [];
    for (let k in optionsObj) {
        let a = {
            value: k,
            label: optionsObj[k]
        }
        newArray.push(a);
    }
    return newArray
}

export function utilsGetAuthority(authorityStatus) {
    let cmdsList = [];
    for (let k in authorityStatus) {
        let item = authorityStatus[k];
        let a = [item.url, item.method];
        cmdsList.push(a);
    }
    let url = '/admin/v1/auth/status?auth_list=' + JSON.stringify(cmdsList);
    businessGet(url).then(res => {
        if (res.data.status == 200) {
            let num = 0;
            for (let k in authorityStatus) {
                authorityStatus[k].show = res.data.data[num];
                num += 1
            }
        }
        else {
            ElMessage({
                showClose: true,
                message: res.data.message,
                type: 'warning'
            });
        }
    })
}

export function utilsExportExcel(url, msg) {
    let confirmMsg = '';
    if (msg) {
        confirmMsg = msg
    }
    else {
        confirmMsg = '此操作将按照搜索条件导出全部数据(数据量较大时请前往 系统配置-下载中心 下载), 是否继续?'
    }
    ElMessageBox.confirm(confirmMsg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(() => {
        businessGet(url).then(res => {
            if (res.data.status == 200) {
                if (res.data.data.file_path) {
                    window.open(res.data.data.file_path)
                }
                else {
                    ElMessage({
                        showClose: true,
                        message: res.data.message,
                        type: 'success'
                    });
                }
            }
            else {
                ElMessage({
                    showClose: true,
                    message: res.data.message,
                    type: 'warning'
                });
            }
        })
    }).catch(() => {

    })
}

export function utilsDownImg(val, fileName) {
    // this.$refs.imageTofile,文件名
    const opts = {
        useCORS: true,
        backgroundColor: null
    }
    html2canvas(val, opts).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        let aLink = document.createElement('a');
        let blob = base64ToBlob(url); //new Blob([content]);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
    })

    function base64ToBlob(code) {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    }
}

export function utilsNotificationJump(val, url) {
    ElNotification({
        title: '成功',
        message: val,
        type: 'success',
        duration: 0,
        onClick: function () {
            router.push(url)
        }
    })
}


export function utilsIdcardHide(val) {
    return val.substring(0,6)+'****'+val.substring(val.length-4)
}
